import { useState } from "react";

export const useToken = () => {
    // Initialisation de l'état avec le token stocké dans localStorage
    const [token, setTokenInternal] = useState(() => {
        return localStorage.getItem("token");
    });

    // Fonction pour mettre à jour le token
    const setToken = newToken => {
        // Stocker le nouveau token dans localStorage
        localStorage.setItem('token', newToken);
        // Mettre à jour l'état du token
        setTokenInternal(newToken);
    }

    // Retourner le token actuel et la fonction pour le mettre à jour
    return [token, setToken];
}
