const chapters = [
    {
        "id": 1,
        "title": "Niveau 1 : Le Trésor des Variables",
        "icon": "path/to/default/icon.png",
        "completedIcon": "path/to/completed/icon.png",
        "description": "Défi : Apprendre les variables simples en Python pour trouver le trésor",
        "difficulty": "debutant",
        "levels": [
            {
                "description": "Défi : Modifier la variable 'coffre' pour que le code affiche 'Trésor trouvé !'",
                "initialCode": "# Défi : Modifier la variable coffre pour que le code affiche 'Trésor trouvé !'\ncoffre = 1\n\nif coffre == 4:\n    print(\"Trésor trouvé !\")\nelse:\n    print(\"Continuez à chercher !!\")",
                "successMessage": "Trésor trouvé !",
                "help": "Pensez à ce que doit être la valeur de 'coffre' pour que l'instruction if soit vraie.",
                "test": [
                    {
                        "text": "Quelle valeur doit avoir la variable 'coffre' pour réussir le défi ?",
                        "choices": ["1", "4", "6"],
                    }
                ],
                "testAnswers": ["4"]
            },
            {
                "description": "Défi : Affiche 'Ahoy, Matelot!' en utilisant la fonction print",
                "initialCode": "# Défi : Affiche 'Ahoy, Matelot!' en utilisant la fonction print\nmessage = \"\"\nprint(message)",
                "successMessage": "Ahoy, Matelot!",
                "help": "Utilisez la fonction print pour afficher le message 'Ahoy, Matelot!'.",
                "type": "code",
                "test": [
                    {
                        "text": "Quelle valeur doit avoir la variable 'message' pour afficher 'Ahoy, Matelot!' ?",
                        "choices": ["Ahoy", "Matelot", "Ahoy, Matelot!"],
                    }
                ],
                "testAnswers": ["Ahoy, Matelot!"]
            },
            {
                "description": "Défi : Écrire le code pour afficher 'Bienvenue à bord'",
                "initialCode": "# Défi : Écrire le code pour afficher 'Bienvenue à bord'",
                "successMessage": "Bienvenue à bord",
                "help": "La fonction que vous devez utiliser pour afficher un message est 'print'.",
                "type": "code",
                "test": [
                    {
                        "text": "Quelle fonction utilise-t-on pour afficher 'Bienvenue à bord' ?",
                        "choices": ["affiche", "print", "echo"],
                    }
                ],
                "testAnswers": ["print"]
            }
        ],
        "quiz": {
            "title": "Quiz sur les variables",
            "questions": [
                {
                    "question": "Que représente une variable en programmation ?",
                    "choices": ["Une valeur fixe", "Un espace de stockage pour une valeur", "Un type de boucle"],
                    "correctAnswer": "Un espace de stockage pour une valeur"
                },
                {
                    "question": "Comment déclare-t-on une variable en Python ?",
                    "choices": ["let variable = valeur", "variable = valeur", "var variable = valeur"],
                    "correctAnswer": "variable = valeur"
                },
                {
                    "question": "Quelle est la fonction utilisée pour afficher un message en Python ?",
                    "choices": ["affiche", "print", "echo"],
                    "correctAnswer": "print"
                }
            ]
        },
        "codingTest": {
            "title": "Test de codage : Écrire une ligne de code",
            "description": "Écrire le code pour afficher 'Bonjour, Monde!'",
            "initialCode": "# Écrire le code pour afficher 'Bonjour, Monde!'",
            "successMessage": "Bonjour, Monde!",
            "help": "La fonction que vous devez utiliser pour afficher un message est 'print'.",
            "correctAnswer": "print(\"Bonjour, Monde!\")"
        }
    },
    {
        id: 2,
        title: "Niveau 2 : Les Cartes au Trésor des Types de Base",
        icon: "path/to/default/icon.png",
        completedIcon: "path/to/completed/icon.png",
        description: "Défi : Apprendre les types de base et utiliser la fonction print pour lire les cartes au trésor",
        difficulty: "debutant",
        levels: [
            {
                description: "Défi : Affiche le texte 'Terre en vue!' en utilisant la fonction print",
                initialCode: `# Défi : Affiche le texte 'Terre en vue!' en utilisant la fonction print
message = ""
print(message)`,
                successMessage: "Terre en vue!",
                help: "Utilisez la fonction print pour afficher le message 'Terre en vue!'.",
                type: "code",
                test: [
                    {
                        text: "Quelle fonction utilise-t-on pour afficher un message à l'écran ?",
                        choices: ["print", "afficher", "echo"],
                    },
                ],
                testAnswers: ["print"],
            },
            {
                description: "Défi : Afficher le nombre 42 pour signaler la longitude",
                initialCode: `# Défi : Afficher le nombre 42 pour signaler la longitude
longitude = 0
print(longitude)`,
                successMessage: "42",
                help: "Assurez-vous que la variable 'longitude' contient la valeur 42.",
                type: "code",
                test: [
                    {
                        text: "Quelle valeur doit avoir la variable 'longitude' pour réussir le défi ?",
                        choices: ["0", "21", "42"],
                    },
                ],
                testAnswers: ["42"],
            },
            {
                description: "Défi : Afficher la somme des pièces d'or",
                initialCode: `# Défi : Afficher la somme des pièces d'or
piece1 = 10
piece2 = 5
somme = 0
print(somme)`,
                successMessage: "15",
                help: "Ajoutez les valeurs de 'piece1' et 'piece2' pour obtenir la somme.",
                type: "code",
                test: [
                    {
                        text: "Quelle opération doit-on faire pour obtenir la somme ?",
                        choices: ["addition", "soustraction", "multiplication"],
                    },
                ],
                testAnswers: ["addition"],
            }
        ]
    },
    {
        id: 3,
        title: "Chapitre 1 : Le Trésor des Variables",
        icon: "path/to/default/icon.png",
        completedIcon: "path/to/completed/icon.png",
        description: "Apprenez ce qu'est une variable et comment l'utiliser pour trouver le trésor",
        difficulty: "debutant",
        levels: [
            {
                description: "Apprenez ce qu'est une variable et comment l'utiliser pour trouver le trésor",
                initialCode: `# Chapitre 1 : Le Trésor des Variables
# Imagine que tu as un coffre de pirate où tu peux ranger des trésors.
# En programmation, ce coffre s'appelle une 'variable'.
# Voici comment tu crées une variable pour stocker ton butin :

butin = 100  # J'ai 100 pièces d'or !

# Et voici une autre variable pour stocker ton navire :

navire = "L'Intrépide"  # Mon navire s'appelle L'Intrépide

# Utilise la fonction print pour afficher ce qu'il y a dans tes variables :

print("Mon butin est de", butin, "pièces d'or")
print("Mon navire s'appelle", navire)`,
                successMessage: "Mon butin est de 100 pièces d'or\nMon navire s'appelle L'Intrépide",
                help: "Utilisez la fonction print pour afficher les valeurs des variables 'butin' et 'navire'.",
                type: "code",
                test: [
                    {
                        text: "Quelle est la variable utilisée pour stocker ton butin ?",
                        choices: ["butin", "navire", "tresor"],
                    },
                    {
                        text: "Quelle est la variable utilisée pour stocker le nom de ton navire ?",
                        choices: ["navire", "bateau", "vaisseau"],
                    },
                ],
                testAnswers: ["butin", "navire"],
            },
            {
                description: "Défi : Change ton butin à 200 pièces d'or et le nom de ton navire à 'Le Vengeur'",
                initialCode: `# Défi : Change ton butin à 200 pièces d'or et le nom de ton navire à 'Le Vengeur'

butin = 100  # J'ai 100 pièces d'or !
navire = "L'Intrépide"  # Mon navire s'appelle L'Intrépide

print("Mon butin est de", butin, "pièces d'or")
print("Mon navire s'appelle", navire)`,
                successMessage: "Mon butin est de 200 pièces d'or\nMon navire s'appelle Le Vengeur",
                help: "Modifiez les valeurs des variables 'butin' et 'navire' pour qu'elles correspondent aux nouvelles valeurs.",
                type: "code",
                test: [
                    {
                        text: "Quel butin dois-tu mettre pour réussir le défi ?",
                        choices: ["100", "200", "300"],
                    },
                    {
                        text: "Quel nom dois-tu mettre pour ton navire pour réussir le défi ?",
                        choices: ["L'Intrépide", "Le Vengeur", "Le Corsaire"],
                    },
                ],
                testAnswers: ["200", "Le Vengeur"],
            }
        ]
    },
    {
        id: 4,
        title: "Niveau 3 : Les Listes de Trésors",
        icon: "path/to/default/icon.png",
        completedIcon: "path/to/completed/icon.png",
        description: "Défi : Modifier la liste des trésors pour afficher les bons éléments.",
        difficulty: "confirme",
        levels: [
            {
                description: "Défi : Modifier la liste des trésors pour afficher \"Rubis, Émeraude, Diamant\"",
                initialCode: `# Défi : Modifier la liste des trésors pour afficher "Rubis, Émeraude, Diamant"
tresors = ["Rubis", "Perle", "Diamant"]

print(", ".join(tresors))`,
                successMessage: "Rubis, Émeraude, Diamant",
                help: "Remplacez 'Perle' par 'Émeraude' dans la liste des trésors.",
                type: "code",
                test: [
                    {
                        text: "Quel trésor dois-tu ajouter dans la liste pour réussir le défi ?",
                        choices: ["Perle", "Émeraude", "Saphir"],
                    },
                ],
                testAnswers: ["Émeraude"],
            },
            {
                description: "Défi : Ajouter 'Raisin' à la liste de fruits pour le festin des pirates",
                initialCode: `# Défi : Ajouter 'Raisin' à la liste de fruits

fruits = ["Pomme", "Banane", "Orange"]
fruits.append("")

print(", ".join(fruits))`,
                successMessage: "Pomme, Banane, Orange, Raisin",
                help: "Utilisez la méthode 'append' pour ajouter 'Raisin' à la liste des fruits.",
                type: "code",
                test: [
                    {
                        text: "Quel fruit dois-tu ajouter à la liste pour le festin des pirates ?",
                        choices: ["Poire", "Raisin", "Fraise"],
                    },
                ],
                testAnswers: ["Raisin"],
            }
        ]
    },
    {
        id: 5,
        title: "Niveau 4 : Les Boucles pour Naviguer",
        icon: "path/to/default/icon.png",
        completedIcon: "path/to/completed/icon.png",
        description: "Défi : Utiliser une boucle pour afficher les nombres de 1 à 5 pour la navigation",
        difficulty: "confirme",
        levels: [
            {
                description: "Défi : Utiliser une boucle pour afficher les nombres de 1 à 5",
                initialCode: `# Défi : Utiliser une boucle pour afficher les nombres de 1 à 5
for i in range(1, 4):
    print(i)`,
                successMessage: "1\n2\n3\n4\n5",
                help: "Ajustez les paramètres de la boucle range pour qu'elle affiche les nombres de 1 à 5.",
                type: "code",
                test: [
                    {
                        text: "Quel nombre dois-tu mettre pour que la boucle affiche jusqu'à 5 ?",
                        choices: ["4", "5", "6"],
                    },
                ],
                testAnswers: ["6"],
            },
            {
                description: "Défi : Utiliser une boucle pour afficher les nombres de 1 à 10 pour la navigation longue",
                initialCode: `# Défi : Utiliser une boucle pour afficher les nombres de 1 à 10

for i in range(1, 5):
    print(i)`,
                successMessage: "1\n2\n3\n4\n5\n6\n7\n8\n9\n10",
                help: "Ajustez les paramètres de la boucle range pour qu'elle affiche les nombres de 1 à 10.",
                type: "code",
                test: [
                    {
                        text: "Quel nombre dois-tu mettre pour que la boucle affiche jusqu'à 10 ?",
                        choices: ["9", "10", "11"],
                    },
                ],
                testAnswers: ["11"],
            }
        ]
    },
    {
        id: 6,
        title: "Niveau 5 : Les Conditions pour Éviter les Tempêtes",
        icon: "path/to/default/icon.png",
        completedIcon: "path/to/completed/icon.png",
        description: "Défi : Utiliser une condition pour vérifier si un nombre est pair pour éviter les tempêtes",
        difficulty: "confirme",
        levels: [
            {
                description: "Défi : Utiliser une condition pour vérifier si un nombre est pair",
                initialCode: `# Défi : Utiliser une condition pour vérifier si un nombre est pair
nombre = 4

if nombre % 2 == 0:
    print("Le nombre est pair")
else:
    print("Le nombre est impair")`,
                successMessage: "Le nombre est pair",
                help: "Utilisez l'opérateur modulo (%) pour vérifier si le nombre est pair.",
                type: "code",
                test: [
                    {
                        text: "Quelle condition utilise-t-on pour vérifier si un nombre est pair ?",
                        choices: ["nombre % 2 == 0", "nombre % 2 == 1", "nombre // 2 == 0"],
                    },
                ],
                testAnswers: ["nombre % 2 == 0"],
            },
            {
                description: "Défi : Vérifier si un nombre est impair pour la boussole des pirates",
                initialCode: `# Défi : Vérifier si un nombre est impair

nombre = 5

if nombre % 2 == 1:
    print("Le nombre est impair")
else:
    print("Le nombre est pair")`,
                successMessage: "Le nombre est impair",
                help: "Utilisez l'opérateur modulo (%) pour vérifier si le nombre est impair.",
                type: "code",
                test: [
                    {
                        text: "Quelle condition utilise-t-on pour vérifier si un nombre est impair ?",
                        choices: ["nombre % 2 == 0", "nombre % 2 == 1", "nombre // 2 == 1"],
                    },
                ],
                testAnswers: ["nombre % 2 == 1"],
            }
        ]
    },
    {
        id: 7,
        title: "Niveau 6 : Les Fonctions pour les Pirates",
        icon: "path/to/default/icon.png",
        completedIcon: "path/to/completed/icon.png",
        description: "Défi : Créer une fonction qui retourne la somme de deux nombres pour les pirates",
        difficulty: "confirme",
        levels: [
            {
                description: "Défi : Créer une fonction qui retourne la somme de deux nombres",
                initialCode: `# Défi : Créer une fonction qui retourne la somme de deux nombres
def somme(a, b):
    return 

print(somme(3, 4))`,
                successMessage: "7",
                help: "Ajoutez les valeurs des paramètres 'a' et 'b' pour retourner la somme.",
                type: "code",
                test: [
                    {
                        text: "Que doit retourner la fonction pour réussir le défi ?",
                        choices: ["a + b", "a - b", "a * b"],
                    },
                ],
                testAnswers: ["a + b"],
            },
            {
                description: "Défi : Créer une fonction qui retourne le produit de deux nombres pour les pirates",
                initialCode: `# Défi : Créer une fonction qui retourne le produit de deux nombres

def produit(a, b):
    return 

print(produit(3, 4))`,
                successMessage: "12",
                help: "Multipliez les valeurs des paramètres 'a' et 'b' pour retourner le produit.",
                type: "code",
                test: [
                    {
                        text: "Que doit retourner la fonction pour réussir le défi ?",
                        choices: ["a + b", "a - b", "a * b"],
                    },
                ],
                testAnswers: ["a * b"],
            }
        ]
    },
    {
        id: 8,
        title: "Niveau 7 : Les Chaînes de caractères pour les Messages en Bouteille",
        icon: "path/to/default/icon.png",
        completedIcon: "path/to/completed/icon.png",
        description: "Défi : Concatenation des chaînes de caractères pour écrire des messages en bouteille",
        difficulty: "confirme",
        levels: [
            {
                description: "Défi : Concatenation des chaînes de caractères",
                initialCode: `# Défi : Concatenation des chaînes de caractères
part1 = "Bonjour "
part2 = "tout le monde!"

message = part1 + part2

print(message)`,
                successMessage: "Bonjour tout le monde!",
                help: "Utilisez l'opérateur + pour concaténer les chaînes de caractères 'part1' et 'part2'.",
                type: "code",
                test: [
                    {
                        text: "Comment concatène-t-on deux chaînes de caractères ?",
                        choices: ["part1 - part2", "part1 + part2", "part1 * part2"],
                    },
                ],
                testAnswers: ["part1 + part2"],
            },
            {
                description: "Défi : Modifier les chaînes pour afficher 'Salut tout le monde!'",
                initialCode: `# Défi : Modifier les chaînes pour afficher 'Salut tout le monde!'

part1 = "Bonjour "
part2 = "tout le monde!"

message = part1 + part2

print(message)`,
                successMessage: "Salut tout le monde!",
                help: "Changez la valeur de 'part1' pour 'Salut '.",
                type: "code",
                test: [
                    {
                        text: "Quelle est la nouvelle valeur de part1 ?",
                        choices: ["Bonjour ", "Salut ", "Hello "],
                    },
                ],
                testAnswers: ["Salut "],
            }
        ]
    },
    {
        id: 9,
        title: "Niveau 8 : Les Dictionnaires pour les Cartes au Trésor",
        icon: "path/to/default/icon.png",
        completedIcon: "path/to/completed/icon.png",
        description: "Défi : Utiliser un dictionnaire pour stocker des informations de cartes au trésor",
        difficulty: "expert",
        levels: [
            {
                description: "Défi : Utiliser un dictionnaire pour stocker des informations",
                initialCode: `# Défi : Utiliser un dictionnaire pour stocker des informations
personne = {"nom": "Alice", "age": 8}

print("Nom:", personne["nom"])
print("Age:", personne["age"])`,
                successMessage: "Nom: Alice\nAge: 8",
                help: "Utilisez la syntaxe 'personne[\"clé\"]' pour accéder aux valeurs du dictionnaire.",
                type: "code",
                test: [
                    {
                        text: "Comment accède-t-on à la valeur associée à la clé 'nom' ?",
                        choices: ["personne['nom']", "personne('nom')", "personne.nom"],
                    },
                ],
                testAnswers: ["personne['nom']"],
            },
            {
                description: "Défi : Ajouter une clé 'ville' avec la valeur 'Paris' à la carte",
                initialCode: `# Défi : Ajouter une clé 'ville' avec la valeur 'Paris'

personne = {"nom": "Alice", "age": 8}
personne["ville"] = ""

print("Nom:", personne["nom"])
print("Age:", personne["age"])
print("Ville:", personne["ville"])`,
                successMessage: "Nom: Alice\nAge: 8\nVille: Paris",
                help: "Ajoutez une nouvelle entrée au dictionnaire en utilisant la syntaxe 'personne[\"ville\"] = \"Paris\"'.",
                type: "code",
                test: [
                    {
                        text: "Quelle valeur doit avoir la clé 'ville' ?",
                        choices: ["Londres", "Paris", "New York"],
                    },
                ],
                testAnswers: ["Paris"],
            }
        ]
    },
    {
        id: 10,
        title: "Niveau 9 : Les Boucles imbriquées pour les Calculs des Pirates",
        icon: "path/to/default/icon.png",
        completedIcon: "path/to/completed/icon.png",
        description: "Défi : Utiliser des boucles imbriquées pour créer une table de multiplication pour les pirates",
        difficulty: "expert",
        levels: [
            {
                description: "Défi : Utiliser des boucles imbriquées pour créer une table de multiplication",
                initialCode: `# Défi : Utiliser des boucles imbriquées pour créer une table de multiplication
for i in range(1, 6):
    for j in range(1, 6):
        print(i * j, end=" ")
    print()`,
                successMessage: "1 2 3 4 5\n2 4 6 8 10\n3 6 9 12 15\n4 8 12 16 20\n5 10 15 20 25",
                help: "Utilisez deux boucles 'for' imbriquées pour multiplier les valeurs de i et j.",
                type: "code",
                test: [
                    {
                        text: "Combien de boucles faut-il utiliser pour créer une table de multiplication ?",
                        choices: ["1", "2", "3"],
                    },
                ],
                testAnswers: ["2"],
            },
            {
                description: "Défi : Créer une table de multiplication jusqu'à 10 pour les pirates",
                initialCode: `# Défi : Créer une table de multiplication jusqu'à 10

for i in range(1, 6):
    for j in range(1, 6):
        print(i * j, end=" ")
    print()`,
                successMessage: "1 2 3 4 5 6 7 8 9 10\n2 4 6 8 10 12 14 16 18 20\n3 6 9 12 15 18 21 24 27 30\n4 8 12 16 20 24 28 32 36 40\n5 10 15 20 25 30 35 40 45 50\n6 12 18 24 30 36 42 48 54 60\n7 14 21 28 35 42 49 56 63 70\n8 16 24 32 40 48 56 64 72 80\n9 18 27 36 45 54 63 72 81 90\n10 20 30 40 50 60 70 80 90 100",
                help: "Ajustez les paramètres des boucles 'for' pour qu'elles aillent de 1 à 10.",
                type: "code",
                test: [
                    {
                        text: "Quel doit être le nombre maximum dans les boucles pour afficher jusqu'à 10 ?",
                        choices: ["9", "10", "11"],
                    },
                ],
                testAnswers: ["10"],
            }
        ]
    }
];

export default chapters;
