import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import chapters from "../data./chapters";
import './TreasureMap.css';
import {FaCheckCircle, FaRegCircle} from "react-icons/fa";
const LandingPage = () => {
    const navigate = useNavigate();
    const [completedChapters, setCompletedChapters] = useState({});

    useEffect(() => {
        // Hotjar tracking code
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:5020861,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');

        // Load completed chapters from localStorage
        const storedCompletedChapters = JSON.parse(localStorage.getItem('completedChapters')) || {};
        setCompletedChapters(storedCompletedChapters);
    }, []);

    const handleChapterClick = (chapterId) => {
        navigate(`/chapter/${chapterId}/level/1`);
    };

    const renderChapters = (difficulty) => {
        return chapters.filter(chapter => chapter.difficulty === difficulty).map((chapter) => (
            <div
                key={chapter.id}
                className="chapter-square"
                onClick={() => handleChapterClick(chapter.id)}
            >
                <div className="chapter-icon">
                    {completedChapters[chapter.id] ? <FaCheckCircle size={60} color="green" /> : <FaRegCircle size={60} color="grey" />}
                </div>
                <div className="chapter-title">
                    {chapter.title}
                </div>
            </div>
        ));
    };

    return (

        <div className="treasure-map-container">
            <h1 className="title">Pyth-Kids</h1>
            <h2 className="subtitle">Débutant</h2>
            <div className="chapter-grid">
                {renderChapters("debutant")}
            </div>
            <h2 className="subtitle">Confirmé</h2>
            <div className="chapter-grid">
                {renderChapters("confirme")}
            </div>
            <h2 className="subtitle">Expert</h2>
            <div className="chapter-grid">
                {renderChapters("expert")}
            </div>
        </div>
    );
};

export default LandingPage;
