import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import courses from "../data./cours";  // Assurez-vous que le chemin est correct
import './coursComponement.css'

const CourseComponent = () => {
    const { id, levelId } = useParams();
    const [currentCourse, setCurrentCourse] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log("id:", id);
        console.log("levelId:", levelId);

        const course = courses.find(course => {
            console.log("course.chapterId:", course.chapterId, "course.levelId:", course.levelId);
            return course.chapterId === parseInt(id, 10) && course.levelId === parseInt(levelId, 10);
        });
        setCurrentCourse(course);
        setLoading(false);
    }, [id, levelId]);

    if (loading) {
        return <div>Chargement du cours...</div>;
    }

    if (!currentCourse) {
        return <div>Aucun cours trouvé.</div>;
    }

    return (
        <div className="course">
            <h2>{currentCourse.title}</h2>
            <p>{currentCourse.description}</p>
            <pre className="code-example">{currentCourse.example}</pre>
            {currentCourse.img && <img src={currentCourse.img} alt={currentCourse.title} className="course-image" />}
        </div>
    );
};

export default CourseComponent;
