import illuchapter1 from "../assets/illuchapter1.jpg";

const courses = [
    {
        chapterId: 1,
        levelId: 1,
        title: "Variables simples",
        img:illuchapter1,
        description: "Une variable est une boîte dans laquelle tu peux mettre une valeur. Voici comment tu peux créer une variable en Python :",
        example: `# Crée une variable appelée 'banane' et lui donne la valeur 10
banane = 10

# Affiche la valeur de 'banane'
print(banane)`
    },
    {
        chapterId: 1,
        levelId: 2,
        title: "Modifier une variable",
        description: "Pour modifier la valeur d'une variable, il suffit de lui assigner une nouvelle valeur. Voici un exemple :",
        example: `# Change la valeur de 'banane'
banane = 5
print(banane)

banane = 10
print(banane)  # Cela affichera 10`
    },
    {
        chapterId: 2,
        levelId: 1,
        title: "Types de base et print",
        description: "Les types de base sont des types de données simples comme les nombres et les chaînes de caractères. La fonction 'print' te permet d'afficher des messages.",
        example: `# Affiche un message
print("Bonjour tout le monde !")

# Affiche un nombre
print(42)`
    },
    {
        chapterId: 2,
        levelId: 2,
        title: "Afficher la somme de deux nombres",
        description: "Pour afficher la somme de deux nombres, il suffit d'utiliser l'opérateur '+'. Voici un exemple :",
        example: `# Affiche la somme de deux nombres
nombre1 = 10
nombre2 = 5
somme = nombre1 + nombre2
print(somme)  # Cela affichera 15`
    },
    {
        chapterId: 3,
        levelId: 1,
        title: "Découvrir les Variables",
        description: "Une variable est une boîte dans laquelle tu peux mettre une valeur. Voici comment tu peux créer une variable en Python :",
        example: `# Crée une variable appelée 'age' et lui donne la valeur 8
age = 8

# Affiche la valeur de 'age'
print("Mon âge est", age)`
    },
    {
        chapterId: 3,
        levelId: 2,
        title: "Modifier une variable",
        description: "Pour modifier la valeur d'une variable, il suffit de lui assigner une nouvelle valeur. Voici un exemple :",
        example: `# Change la valeur de 'age'
age = 9
print("Mon âge est", age)  # Cela affichera 9`
    },
    {
        chapterId: 4,
        levelId: 1,
        title: "Les Listes",
        description: "Une liste est une collection ordonnée de valeurs. Voici comment tu peux créer une liste en Python :",
        example: `# Crée une liste appelée 'fruits' et lui donne des valeurs
fruits = ["Pomme", "Banane", "Orange"]

# Affiche la liste des fruits
print(fruits)`
    },
    {
        chapterId: 4,
        levelId: 2,
        title: "Modifier une liste",
        description: "Pour modifier une liste, il suffit d'utiliser des méthodes comme 'append' pour ajouter des éléments. Voici un exemple :",
        example: `# Ajoute un fruit à la liste 'fruits'
fruits = ["Pomme", "Banane", "Orange"]
fruits.append("Raisin")
print(fruits)  # Cela affichera ["Pomme", "Banane", "Orange", "Raisin"]`
    },
    {
        chapterId: 5,
        levelId: 1,
        title: "Les Boucles",
        description: "Une boucle permet de répéter une série d'instructions. Voici comment tu peux créer une boucle en Python :",
        example: `# Utilise une boucle pour afficher les nombres de 1 à 5
for i in range(1, 6):
    print(i)`
    },
    {
        chapterId: 5,
        levelId: 2,
        title: "Boucles imbriquées",
        description: "Une boucle imbriquée est une boucle à l'intérieur d'une autre boucle. Voici un exemple :",
        example: `# Utilise des boucles imbriquées pour créer une table de multiplication
for i in range(1, 6):
    for j in range(1, 6):
        print(i * j, end=" ")
    print()`
    },
    {
        chapterId: 6,
        levelId: 1,
        title: "Les Conditions",
        description: "Une condition permet d'exécuter du code uniquement si une certaine condition est remplie. Voici un exemple :",
        example: `# Utilise une condition pour vérifier si un nombre est pair
nombre = 4

if nombre % 2 == 0:
    print("Le nombre est pair")
else:
    print("Le nombre est impair")`
    },
    {
        chapterId: 6,
        levelId: 2,
        title: "Vérifier si un nombre est impair",
        description: "Pour vérifier si un nombre est impair, il suffit d'utiliser l'opérateur '%'. Voici un exemple :",
        example: `# Vérifie si un nombre est impair
nombre = 5

if nombre % 2 == 1:
    print("Le nombre est impair")
else:
    print("Le nombre est pair")`
    },
    {
        chapterId: 7,
        levelId: 1,
        title: "Les Fonctions",
        description: "Une fonction est un bloc de code réutilisable. Voici comment tu peux créer une fonction en Python :",
        example: `# Crée une fonction qui retourne la somme de deux nombres
def somme(a, b):
    return a + b

print(somme(3, 4))  # Cela affichera 7`
    },
    {
        chapterId: 7,
        levelId: 2,
        title: "Créer une fonction",
        description: "Pour créer une fonction, il suffit d'utiliser le mot-clé 'def'. Voici un exemple :",
        example: `# Crée une fonction qui retourne le produit de deux nombres
def produit(a, b):
    return a * b

print(produit(3, 4))  # Cela affichera 12`
    },
    {
        chapterId: 8,
        levelId: 1,
        title: "Les Chaînes de caractères",
        description: "Une chaîne de caractères est une séquence de caractères. Voici comment tu peux créer une chaîne de caractères en Python :",
        example: `# Crée deux chaînes de caractères et les concatène
part1 = "Bonjour "
part2 = "tout le monde!"

message = part1 + part2
print(message)  # Cela affichera "Bonjour tout le monde!"`
    },
    {
        chapterId: 8,
        levelId: 2,
        title: "Modifier une chaîne de caractères",
        description: "Pour modifier une chaîne de caractères, il suffit de la concaténer avec une autre chaîne. Voici un exemple :",
        example: `# Modifie les chaînes de caractères pour afficher "Salut tout le monde!"
part1 = "Salut "
part2 = "tout le monde!"

message = part1 + part2
print(message)  # Cela affichera "Salut tout le monde!"`
    },
    {
        chapterId: 9,
        levelId: 1,
        title: "Les Dictionnaires",
        description: "Un dictionnaire est une collection non ordonnée de paires clé-valeur. Voici comment tu peux créer un dictionnaire en Python :",
        example: `# Crée un dictionnaire pour stocker des informations sur une personne
personne = {"nom": "Alice", "age": 8}

print("Nom:", personne["nom"])
print("Age:", personne["age"])`
    },
    {
        chapterId: 9,
        levelId: 2,
        title: "Modifier un dictionnaire",
        description: "Pour modifier un dictionnaire, il suffit d'ajouter ou de modifier des paires clé-valeur. Voici un exemple :",
        example: `# Ajoute une clé 'ville' avec la valeur 'Paris'
personne = {"nom": "Alice", "age": 8}
personne["ville"] = "Paris"

print("Nom:", personne["nom"])
print("Age:", personne["age"])
print("Ville:", personne["ville"])`
    },
    {
        chapterId: 10,
        levelId: 1,
        title: "Les Boucles imbriquées",
        description: "Une boucle imbriquée est une boucle à l'intérieur d'une autre boucle. Voici un exemple :",
        example: `# Utilise des boucles imbriquées pour créer une table de multiplication
for i in range(1, 6):
    for j in range(1, 6):
        print(i * j, end=" ")
    print()`
    },
    {
        chapterId: 10,
        levelId: 2,
        title: "Table de multiplication jusqu'à 10",
        description: "Pour créer une table de multiplication jusqu'à 10, il suffit d'augmenter la portée de la boucle. Voici un exemple :",
        example: `# Utilise des boucles imbriquées pour créer une table de multiplication jusqu'à 10
for i in range(1, 11):
    for j in range(1, 11):
        print(i * j, end=" ")
    print()`
    }
];

export default courses;
