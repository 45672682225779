import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToken } from "../auth/useToken";
import axios from 'axios';

export const SignupPage = () => {
    const [token, setToken] = useToken();
    const [errorMessage, setError] = useState("");
    const [emailValue, setEmail] = useState("");
    const [passwordValue, setPassword] = useState("");
    const [passwordConfirmValue, setPasswordConfirm] = useState("");
    const navigate = useNavigate();

    const onSignupClicked = async () => {
        try {
            const response = await axios.post("https://www.pyth-kids.com/api/sign-up", {
                email: emailValue,
                password: passwordValue
            });
            const { token } = response.data;
            setToken(token);
            navigate('/listechapter');
        } catch (error) {
            setError("Signup failed. Please try again.");
        }
    };

    return (
        <div className="content-container">
            <h1>Inscription</h1>
            {errorMessage && <div className="fail">{errorMessage}</div>}
            <input
                value={emailValue}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="tonemail@gmail.com"
            />
            <input
                value={passwordValue}
                onChange={e => setPassword(e.target.value)}
                type="password"
                placeholder="mot de passe"
            />
            <hr />
            <input
                value={passwordConfirmValue}
                onChange={e => setPasswordConfirm(e.target.value)}
                type="password"
                placeholder="confirme ton mot de passe"
            />
            <button
                disabled={!emailValue || !passwordValue || passwordConfirmValue !== passwordValue}
                onClick={onSignupClicked}
            >
                Inscription
            </button>
            <button
                onClick={() => navigate("/login")}
            >
                Déjà inscrit? Connecte-toi
            </button>
        </div>
    );
};
