import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useToken } from "../auth/useToken";

export const LoginPage = () => {
    const [token , setToken] = useToken();
    const [errorMessage, setError] = useState("");
    const [emailValue, setEmail] = useState("");
    const [passwordValue, setPassword] = useState("");
    const navigate = useNavigate();

    const onLoginClicked = async () => {
        try {
            const response = await axios.post("https://www.pyth-kids.com/api/login", {
                email: emailValue,
                password: passwordValue,
            });
            const { token } = response.data;
            setToken(token);
            navigate('/listechapter');
        } catch (error) {
            setError("Login failed. Please try again.");
        }
    }

    return (
        <div className="content-container">
            <h1>Log In</h1>
            {errorMessage && <div className="fail">{errorMessage}</div>}
            <input
                value={emailValue}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="tonemail@gmail.com"
            />
            <input
                value={passwordValue}
                onChange={e => setPassword(e.target.value)}
                type="password"
                placeholder="mot de passe"
            />
            <hr />
            <button
                disabled={!emailValue || !passwordValue}
                onClick={onLoginClicked}
            >
                Connexion
            </button>
            <button
                onClick={() => navigate("/forgot-password")}
            >
                Mot de passe oublié
            </button>
            <button
                onClick={() => navigate("/signup")}
            >
                Pas encore inscrit ? Inscris-toi
            </button>
        </div>
    );
}
