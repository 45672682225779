import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import chapters  from "../data./chapters";
import courses from "../data./cours";
import { EditorView, keymap, lineNumbers } from '@codemirror/view';
import { python } from '@codemirror/lang-python';
import { EditorState } from '@codemirror/state';
import { oneDark } from '@codemirror/theme-one-dark';
import { defaultKeymap } from '@codemirror/commands';
import { searchKeymap, highlightSelectionMatches } from '@codemirror/search';
import './CodeChallenge.css';
import CourseComponent from "./coursComponent";

const apiUrl = "https://pythkidsapi.fly.dev";

const CodeChallenge = () => {
    const { id, levelId } = useParams();
    const navigate = useNavigate();
    const [currentChallenge, setCurrentChallenge] = useState(null);
    const [code, setCode] = useState('');
    const [output, setOutput] = useState('');
    const [success, setSuccess] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const editorRef = useRef(null);
    const editorViewRef = useRef(null);

    useEffect(() => {
        console.log("Fetching challenge for id:", id, "levelId:", levelId);
        const chapter = chapters.find((chap) => chap.id === parseInt(id, 10));
        if (chapter) {
            const level = levelId ? chapter.levels[parseInt(levelId, 10) - 1] : chapter;
            setCurrentChallenge(level);
            setCode(level ? level.initialCode : '');
            setSuccess(false);
            setOutput('');
        }
    }, [id, levelId]);

    useEffect(() => {
        if (currentChallenge && editorRef.current) {
            const startState = EditorState.create({
                doc: currentChallenge.initialCode,
                extensions: [
                    keymap.of([...defaultKeymap, ...searchKeymap]),
                    python(),
                    oneDark,
                    lineNumbers(),
                    highlightSelectionMatches(),
                    EditorView.editable.of(true),
                    EditorView.updateListener.of(update => {
                        if (update.docChanged) {
                            setCode(update.state.doc.toString());
                        }
                    })
                ]
            });

            if (editorViewRef.current) {
                editorViewRef.current.destroy();
            }

            editorViewRef.current = new EditorView({
                state: startState,
                parent: editorRef.current
            });
        }
    }, [currentChallenge]);

    const markChapterAsCompleted = (chapterId) => {
        const completedChapters = JSON.parse(localStorage.getItem('completedChapters')) || {};
        completedChapters[chapterId] = true;
        localStorage.setItem('completedChapters', JSON.stringify(completedChapters));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(apiUrl + "/run", { code });
            const output = response.data.output;

            setOutput(output);
            if (output.includes(currentChallenge.successMessage)) {
                setSuccess(true);
                markChapterAsCompleted(id);
            } else {
                setSuccess(false);
            }
        } catch (error) {
            console.error('Error executing code:', error);
            setOutput('Error executing code');
            setSuccess(false);
        }
    };

    const handleNextChallenge = () => {
        const currentChapter = chapters.find((chap) => chap.id === parseInt(id, 10));
        if (!currentChapter) {
            alert('Défi non trouvé');
            return;
        }

        const currentLevelIndex = parseInt(levelId, 10) - 1;
        const nextLevelIndex = currentLevelIndex + 1;

        if (nextLevelIndex < currentChapter.levels.length) {
            navigate(`/chapter/${id}/level/${nextLevelIndex + 1}`);
        } else {
            alert('Vous avez terminé tous les défis de ce chapitre !');
        }
    };

    const handleBackToHome = () => {
        navigate('/');
    };

    const calculateProgress = () => {
        const chapter = chapters.find((chap) => chap.id === parseInt(id, 10));
        if (!chapter || !levelId) return 0;
        const totalLevels = chapter.levels.length;
        const currentLevel = parseInt(levelId, 10);
        return (currentLevel / totalLevels) * 100;
    };

    if (!currentChallenge) {
        return <div>Chargement...</div>;
    }

    return (
        <div className="code-challenge">
            <h1>{currentChallenge.title || `Niveau ${levelId}`}</h1>
            <p>{currentChallenge.description}</p>
            <div className="progress-bar">
                <div className="progress" style={{ width: `${calculateProgress()}%` }}></div>
            </div>
            <form onSubmit={handleSubmit} style={{width: '100%'}}>
                <div ref={editorRef} className="code-editor"></div>
                <br/>
                <button type="button" className="help-button" onClick={() => setShowHelp(true)}>Aide</button>
                <h2>Résultat:</h2>
                <pre className="output">{output}</pre>
                {success && (
                    <div className="success-message">
                        <h2>Défi réussi ! 🎉</h2>
                        <button onClick={handleNextChallenge} className="next-challenge-button">Prochain Défi</button>
                    </div>
                )}
                {!success && (
                    <h2>Essayez encore !</h2>
                )}
                <button type="submit" className="run-button">Run Code</button>
            </form>

            <button onClick={handleBackToHome} className="back-to-home-button">Retour à la page d'accueil</button>

            {showHelp && (
                <div className="help-popup">
                    <div className="help-content">
                        <h3>Aide</h3>
                        <p>{currentChallenge.help}</p>
                        <button onClick={() => setShowHelp(false)}>Fermer</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CodeChallenge;
