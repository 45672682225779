import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const UserInfoPage = () => {
    const navigate = useNavigate();

    // States pour les informations de l'utilisateur
    const [username, setUsername] = useState('');
    const [userType, setUserType] = useState(''); // 'child' ou 'adult'
    const [classLevel, setClassLevel] = useState(''); // Niveau de classe pour les enfants
    const [favoriteFood, setFavoriteFood] = useState('');
    const [hairColor, setHairColor] = useState('');
    const [bio, setBio] = useState('');

    // States pour les messages de succès et d'erreur
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    // useEffect pour masquer automatiquement les messages de succès et d'erreur après 3 secondes
    useEffect(() => {
        if (showSuccessMessage || showErrorMessage) {
            setTimeout(() => {
                setShowSuccessMessage(false);
                setShowErrorMessage(false);
            }, 3000);
        }
    }, [showSuccessMessage, showErrorMessage]);

    const saveChanges = async () => {
        // Envoyer une requête au serveur pour mettre à jour les informations de l'utilisateur
        alert('Save functionality not implemented yet');
    }

    const logOut = () => {
        // Déconnecter l'utilisateur et le rediriger vers la page de connexion
        alert('Log out functionality not implemented yet');
    }

    const resetValues = () => {
        // Réinitialiser les valeurs des champs de saisie
        alert('Reset functionality not implemented yet');
    }

    // JSX du composant
    return (
        <div className="content-container">
            <h1>Crée ton nom d'aventurier</h1>
            {showSuccessMessage && <div className="success">Successfully saved user data!</div>}
            {showErrorMessage && <div className="fail">Uh oh... something went wrong and we couldn't save changes</div>}

            <label>
                Nom d'utilisateur:
                <input
                    onChange={e => setUsername(e.target.value)}
                    value={username}
                    placeholder="Entrez votre nom d'utilisateur" />
            </label>

            <label>
                Êtes-vous un enfant ou un adulte ?
                <select onChange={e => setUserType(e.target.value)} value={userType}>
                    <option value="">Sélectionner...</option>
                    <option value="child">Enfant</option>
                    <option value="adult">Adulte</option>
                </select>
            </label>

            {userType === 'child' && (
                <label>
                    Choisissez votre classe:
                    <select onChange={e => setClassLevel(e.target.value)} value={classLevel}>
                        <option value="">Sélectionner...</option>
                        <option value="CE1">CE1</option>
                        <option value="CE2">CE2</option>
                        <option value="CM1">CM1</option>
                        <option value="CM2">CM2</option>
                        <option value="6ème">6ème</option>
                        <option value="5ème">5ème</option>
                        <option value="4ème">4ème</option>
                        <option value="3ème">3ème</option>
                    </select>
                </label>
            )}

            <label>
                Nourriture préférée:
                <input
                    onChange={e => setFavoriteFood(e.target.value)}
                    value={favoriteFood}
                    placeholder="Votre nourriture préférée" />
            </label>

            <label>
                Couleur des cheveux:
                <input
                    onChange={e => setHairColor(e.target.value)}
                    value={hairColor}
                    placeholder="Votre couleur de cheveux" />
            </label>

            <label>
                Bio:
                <input
                    onChange={e => setBio(e.target.value)}
                    value={bio}
                    placeholder="Dites-nous quelque chose sur vous" />
            </label>

            <hr />
            <button onClick={saveChanges}>Save Changes</button>
            <button onClick={resetValues}>Reset Values</button>
            <button onClick={logOut}>Log Out</button>
        </div>
    );
}
